import {Observable} from 'rxjs';
import {Slideshow} from '../models/slideshow';
import {Preferences} from '../models/preferences';
import {IBulkCollectionChangeResponse} from './bulk-collection-change-response.interface';

export interface ICollectionResponse {
  slideshow: Slideshow;
  user_preferences: Preferences;
}

/**
 * @deprecated use domain libs instead
 */
export interface ISlideshowService {
  getBySlug(slug: string): Observable<ICollectionResponse>;
  allowFavoritesForAllCollections(): Observable<IBulkCollectionChangeResponse>;
}
