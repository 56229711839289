import {ITransition} from './transition';

export interface ITemplate {
  is_custom: boolean;
  name: string;
  tempo_type: string;
  id: number;
  matching_images: number;
  version: number;
  configs_path: string | null;
  transitions: ITransition[];
  tempo_id: number;
}
