import {ApolloClientOptions, InMemoryCache, NormalizedCacheObject, Operation} from '@apollo/client';
import {BatchOptions} from 'apollo-angular/http/types';
import {inject} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';
import {GQL_CACHE_TOKEN} from '../tokens/cache.token';
import {getMainDefinition} from '@apollo/client/utilities';
import {Kind, OperationTypeNode} from 'graphql/language';

export abstract class Resolver {
  protected readonly platform = inject(PlatformEnvironment);
  protected readonly cache = inject(GQL_CACHE_TOKEN, {optional: true}) ?? new InMemoryCache();

  protected clientOptionsBase: Partial<ApolloClientOptions<NormalizedCacheObject>> = {
    name: this.platform.PX_OAUTH_CLIENT_ID,
    version: this.platform.APOLLO_STUDIO_VERSION,
  };
  protected httpLinkOptionsBase: BatchOptions = {
    withCredentials: false,
  };

  protected batchOperationTest = (operation: Operation): boolean => {
    const {canBeBatched} = operation.getContext();

    return canBeBatched || canBeBatched === undefined;
  };

  protected subscriptionOperationTest = ({query}: Operation): boolean => {
    const definition = getMainDefinition(query);
    return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
  };
}
