import {ILanguage, Languages, PlatformEnvironment} from '@px/shared/env';
import {
  IPSSThemesCommonSettings,
  IPSSThemesPlatformConfig,
  PSSThemesFeatureConfig,
  PSSThemesFeatureList,
  PSSThemesPlatformFeatures,
} from './platform-env.types';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export class PSSThemesPlatformEnvironment
  extends PlatformEnvironment<PSSThemesFeatureList, PSSThemesFeatureConfig>
  implements IPSSThemesPlatformConfig
{
  override LANGUAGE_LIST: ILanguage[] = [
    {id: 0, title: marker('Automatic'), value: Languages.auto, disabled: false, locales: []},
    {id: 1, title: marker('English'), value: Languages.en, disabled: false, locales: ['en_US', 'en_GB']},
    {id: 2, title: marker('Portuguese'), value: Languages.pt, disabled: false, locales: ['pt_BR', 'pt_PT']},
    {id: 7, title: marker('Spanish'), value: Languages.es, disabled: false, locales: ['es_ES', 'es_MX']},
    {id: 3, title: marker('Russian'), value: Languages.ru, disabled: false, locales: ['ru_RU']},
    {id: 6, title: marker('German'), value: Languages.de, disabled: false, locales: ['de_DE']},
    {id: 4, title: marker('Ukrainian'), value: Languages.uk, disabled: false, locales: ['uk_UA']},
    {id: 5, title: marker('Italian'), value: Languages.it, disabled: false, locales: ['it_IT']},
    {id: 8, title: marker('French'), value: Languages.fr, disabled: false, locales: ['fr_FR']},
  ];

  override readonly COMMON_SETTINGS: IPSSThemesCommonSettings = {
    MAX_UPLOAD_FILE_SIZE: 1,
    SLIDESHOW_LIMITS: {
      MIN_SLIDE_DURATION: 6,
    },
    MAX_DPR: 4,
    FRAMES_PER_SEC: 60,
    CHANGE_SEGMENT_ANIMATION_TIME: 150,
    AUTO_TRANSITION_DURATION: 60,
    FADE_TRANSITION_DURATION: {
      START: 30,
      END: 108,
    },
    LOGO: {
      MIN_HEIGHT: 150,
      MIN_THUMB_HEIGHT: 100,
      MIN_WIDTH: 300,
      AVAILABLE_LOGO_EXTENSIONS: ['.jpg', '.jpeg', '.png'],
    },
  };

  MUST_PLAY_BEFORE_VIEW_TRACK_S = 10;

  readonly FACEBOOK_APP_ID: string = '';
  readonly debugForceSlideshowURL: string = '';

  get hasPssThemesGraphqlApiFeature(): boolean {
    return this.hasFeature(PSSThemesPlatformFeatures.PSS_THEMES_GRAPHQL_API);
  }

  setupHeaders(head: HTMLHeadElement): void {
    const preconnectUrls: string[] = Array.from(
      new Set([this.API_URL, this.GRAPH_QL_ENDPOINT, this.IMAGES_PATH].map(item => new URL(item).origin))
    );

    try {
      for (const url of preconnectUrls) {
        const link = document.createElement('link');
        link.rel = 'preconnect';
        link.href = url;

        head.appendChild(link);
      }
    } catch (e) {
      console.error('Error while setting up headers', e);
    }
  }

  hasCheckUpdatesFeature(): boolean {
    return this.hasFeature(PSSThemesPlatformFeatures.CHECK_UPDATES);
  }
}
