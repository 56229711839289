export interface IFeatureImageBase {
  image_1080p?: string | null;
  id: number;
  mediaId?: string; //GQL related
  mediaItemId?: string; //GQL related
}

export interface IFeatureImage extends IFeatureImageBase {
  image_path: string;
  image_link2k?: string; //GQL related
  image_link4k?: string; //GQL related
  active_storage_service: string | null;
  image_1080p: string | null;
  image_1080pWBlur?: string | null;
  width?: number;
  height?: number;
  cropped?: boolean;
  cropping_data?: Record<string, ICroppingData>;
}

export interface ICroppingData {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IPhoto extends IFeatureImage {
  name: string;
  height: number;
  height_4k: number | null;
  image_2k_hd?: string;
  image_4k: string | null;
  is_low_resolution: boolean;
  is_color_profile_detected?: boolean;
  is_color_profile_updated?: boolean;
  order?: number;
  manual_order?: number | null;
  width: number;
  width_4k: number | null;
  thumbnail?: string;
  selected?: boolean;
  image_2k_hd_white?: string | null;
  image_4k_video_white?: string | null;
  image_4k_video?: string | null;
  original_width?: number;
  original_height?: number;
  date_taken: number;
}
