import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Product} from '@px/shared/data-access/product-product-family';
import set from 'lodash/set';
import {Languages} from './enums/languages';
import {IApiHealthCheck} from './interfaces/api-health-check';
import {
  EventDateFormatOverrides,
  IAvailableFilesExtensions,
  IFeatures,
  ILanguage,
  ILatestBrowsersVer,
  IPlatformConfig,
} from './interfaces/platform-config';

export class PlatformEnvironment<T extends string = string, D extends Record<string, unknown> = Record<string, unknown>>
  implements IPlatformConfig<T, D>
{
  static readonly NO_CACHE_KEY = 'no-cache';
  static readonly APP_VERSION = process.env.APP_VERSION;
  static readonly CACHE_OFF = process.env.CACHE_OFF;

  static readonly GIT_COMMIT = process.env.GIT_COMMIT;
  static readonly GIT_BRANCH = process.env.GIT_BRANCH;
  static readonly DEPLOY_ENV = process.env.DEPLOY_ENV;

  static readonly IS_DEV = process.env.IS_DEV;
  static readonly APP_NAME = process.env.APP_NAME;
  static readonly CYPRESS_IS_CI = process.env.CYPRESS_IS_CI;

  private SA_GRAPH_QL_ENDPOINT_INTERNAL!: string;

  healthCheck!: IApiHealthCheck;

  BASE_LANG = Languages.en;
  LANGUAGES: Languages[] = [];

  LANGUAGE_LIST: ILanguage[] = [
    {id: 0, title: marker('Automatic'), value: Languages.auto, disabled: false},
    {id: 1, title: marker('English'), value: Languages.en, disabled: false},
    {id: 2, title: marker('Portuguese'), value: Languages.pt, disabled: false},
    {id: 3, title: marker('Russian'), value: Languages.ru, disabled: false},
    {id: 4, title: marker('Ukrainian'), value: Languages.uk, disabled: false},
    {id: 5, title: marker('Italian'), value: Languages.it, disabled: false},
    {id: 6, title: marker('German'), value: Languages.de, disabled: false},
    {id: 7, title: marker('Spanish'), value: Languages.es, disabled: false},
    {id: 8, title: marker('French'), value: Languages.fr, disabled: false},
  ];

  PX_PRODUCTS_CLIENT_ID!: Record<Product, string>;
  readonly FIRST_PROMOTER!: {TOKEN: string; DOMAIN: string};
  readonly FIRST_PROMOTER_ENABLED!: boolean;
  readonly production!: boolean;
  readonly GENERATE_MANIFEST!: boolean;
  readonly PIXELLU_URL!: string;
  readonly PIXELLU_ACCOUNT_URL!: string;
  readonly PIXELLU_OAUTH_URL!: string;
  readonly PX_OAUTH_CLIENT_ID!: string;
  readonly PSF_HOST!: string;
  readonly PSS_HOST!: string;
  readonly ORDERS_HOST!: string;
  readonly AA_PA_HOST!: string;
  readonly P1S_HOST!: string;
  readonly CLOUD_PROOFING_API_URL!: string;
  readonly GA_INFO!: string;
  readonly AUDIOS_INFO!: string;
  readonly SUBMIT_FEEDBACK!: string;
  readonly BM_INFO!: string;
  readonly BM_SEGMENT_INFO!: string;
  readonly CLIENT_INTERACTIONS!: string;
  readonly CUSTOM_DOMAIN!: string;
  readonly EMBED_INFO!: string;
  readonly IMAGES_FAILING_INFO!: string;
  readonly IMAGES_INFO!: string;
  readonly LOGOS_INFO!: string;
  readonly SLIDESHOWS_DURATION_INFO!: string;
  readonly DISABLE_ONBEFOREUNLOAD!: boolean;
  readonly GLOBAL_VOLUME!: number;
  readonly DEBUG!: boolean;
  readonly SCOPE!: string;
  readonly S3_URL!: string;
  readonly CF_URL!: string;
  readonly UPLOADER_API!: string;
  readonly CHARGEBEE_SDK_URL!: string;
  readonly CHARGEBEE_SITE!: string;
  readonly CHARGEBEE_API_KEY!: string;
  readonly IMAGES_PATH!: string;
  readonly HOST!: string;
  readonly API_URL!: string;
  readonly AUTH_API_URL!: string;
  readonly ULF_URL!: string;
  readonly GRAPH_QL_ENDPOINT!: string;
  readonly GRAPH_QL_ENDPOINT_WS!: string;
  readonly GRAPH_QL_REQUESTS_WITH_AUTH_HEADER: string[] = [];
  readonly API_IMG!: string;
  readonly NO_AUTH_REDIRECT_URL!: string;
  readonly SENTRY_ENABLED!: boolean;
  readonly SENTRY_DSN!: string;
  readonly GA_ENABLED!: boolean;
  readonly GA_TRACKING_ID!: string;
  readonly MIXPANEL_ENABLED!: boolean;
  readonly MIXPANEL_TOKEN!: string;
  readonly RUDDERSTACK_ENABLED!: boolean;
  readonly RUDDERSTACK_WRITE_KEY!: string;
  readonly RUDDERSTACK_DATA_PLANE_URL!: string;
  readonly BEACON_ENABLED!: boolean;
  readonly BEACON_ID!: string;
  readonly GTM_ENABLED!: boolean;
  readonly GTM_ID!: string;
  readonly GTM_ENV!: string;
  readonly DELIGHTED_SNIPPET_KEY!: string;
  readonly DELIGHTED_ENABLED!: boolean;
  readonly FEATURES: IFeatures<T> = {};
  readonly SUPPORT_URLS!: {[key: string]: string};
  readonly FEATURE_CONFIG!: D;
  readonly EVENT_DATE_FORMAT_OVERRIDES!: EventDateFormatOverrides;
  readonly AVAILABLE_FILES_EXTENSIONS!: IAvailableFilesExtensions;
  readonly COMMON_SETTINGS!: {
    readonly MAX_UPLOAD_FILE_SIZE: number;
    readonly FRAMES_PER_SEC: number;
    readonly LOGO: {
      MIN_HEIGHT: number;
      MIN_THUMB_HEIGHT: number;
      MIN_WIDTH: number;
      AVAILABLE_LOGO_EXTENSIONS: string[];
    };
    readonly s3signature?: {
      minExpirationTime: number;
    };
  };
  readonly API!: {
    readonly URL: string;
  };

  readonly LATEST_BROWSERS_VER: ILatestBrowsersVer = {
    chrome: '>=100',
    firefox: '>=100',
    safari: '>=13.0',
    edge: '>=100',
  };

  readonly CONFIG_CAT!: string;
  readonly GQL_BATCH!: boolean;
  readonly GQL_PERSIST!: boolean;
  readonly API_PERSIST_TTL!: number;
  readonly API_PERSIST!: boolean;
  readonly DISABLE_SERVICE_WORKER!: boolean;
  readonly DATA_DOG_ENABLED!: boolean;
  readonly DATA_DOG_APP_ID!: string;
  readonly DATA_DOG_CLIENT_TOKEN!: string;
  readonly DATA_DOG_SERVICE!: string;
  readonly DATA_DOG_SESSION_SAMPLE_RATE!: number;
  readonly DATA_DOG_SESSION_REPLAY_SAMPLE_RATE!: number;
  readonly SUBSCRIPTION_ACTIVATION_WAITING_TIMEOUT!: number;
  readonly AIRTABLE_KEY!: string;
  readonly AIRTABLE_BASE_ID!: string;
  readonly PROJECT_GROUP!: string;

  readonly STORAGE_CLASS = 'STANDARD_IA';

  get SA_GRAPH_QL_ENDPOINT(): string {
    return this.SA_GRAPH_QL_ENDPOINT_INTERNAL;
  }

  set SA_GRAPH_QL_ENDPOINT(value: string) {
    this.SA_GRAPH_QL_ENDPOINT_INTERNAL = value;
  }

  get GIT_COMMIT(): string {
    return PlatformEnvironment.GIT_COMMIT as string;
  }
  get GIT_BRANCH(): string {
    return PlatformEnvironment.GIT_BRANCH as string;
  }

  get DEPLOY_ENV(): string {
    return PlatformEnvironment.DEPLOY_ENV as string;
  }

  get APP_VERSION(): string {
    return PlatformEnvironment.APP_VERSION as string;
  }

  get APOLLO_STUDIO_VERSION(): string {
    return this.APP_VERSION || `${this.GIT_BRANCH}-${this.GIT_COMMIT}`;
  }

  get APP_NAME(): string {
    return PlatformEnvironment.APP_NAME ?? '';
  }

  get SMARTSLIDES_PRICING_URL(): string {
    return `${this.PIXELLU_URL}/smartslides/pricing`;
  }

  get SMARTSLIDES_PRICING_WITH_PARAMS_URL(): string {
    return `${this.PIXELLU_URL}/smartslides/pricing?utm_medium=app&utm_source=wizard&utm_campaign=ss-cont&utm_content=share-slideshow-upgrade`;
  }

  get PIXELLU_ACCOUNT_SUBSCRIPTIONS_URL(): string {
    return `${this.PIXELLU_ACCOUNT_URL}/`;
  }

  get PIXELLU_ACCOUNT_PROFILE_URL(): string {
    return `${this.PIXELLU_ACCOUNT_URL}/profile/`;
  }

  get IS_DEV(): boolean {
    return !!PlatformEnvironment.IS_DEV;
  }

  constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__PLATFORM_ENV__ = this;
  }

  static getQueryString(): string {
    return `${this.NO_CACHE_KEY}=${this.CACHE_OFF}`;
  }

  getKey<T>(key: string): T {
    return (this as Record<string, T>)[key] as T;
  }

  getQueryString(): string {
    return PlatformEnvironment.getQueryString();
  }

  hasFeature(feature: T): boolean {
    return !!this.FEATURES[feature];
  }

  getFeatureConfig(feature: T): D[T] {
    return this.FEATURE_CONFIG?.[feature];
  }

  printVersion(): void {
    // eslint-disable-next-line no-console
    console.log(
      `%cVersion: ${PlatformEnvironment.APP_VERSION?.trim()}`,
      'background: #EBF5F8; color: gray; font-size: x-medium; border-radius: 5px; padding: 10px;'
    );

    if (!this.production) {
      // eslint-disable-next-line no-console
      console.log(
        `%cFE commit: ${PlatformEnvironment.GIT_COMMIT};\nFE branch: ${PlatformEnvironment.GIT_BRANCH};\nBE commit: ${this.healthCheck?.git_commit ?? '---'};\nBE env: ${this.healthCheck?.env ?? '---'}`,
        'background: #EBF5F8; color: gray; font-size: x-medium; border-radius: 5px; padding: 10px; margin: 5px 0;'
      );

      // eslint-disable-next-line no-console
      console.log(
        `%cName: ${PlatformEnvironment.APP_NAME?.trim()}`,
        'background: #EBF5F8; color: gray; font-size: x-medium; border-radius: 5px; padding: 10px;'
      );
    }
  }

  setApiHealthCheck(healthCheck: IApiHealthCheck): void {
    this.healthCheck = healthCheck;
  }

  isGQLOperationWithHeader(operationName?: string): boolean {
    return !!operationName && this.GRAPH_QL_REQUESTS_WITH_AUTH_HEADER.includes(operationName);
  }

  getAuthNavigationUrl(): string {
    throw new Error('getAuthNavigationUrl is not implemented');
  }

  setFeatureState(feature: T, state: boolean): void {
    this.FEATURES[feature] = state;
  }

  setFeatureConfig(feature: T, configPropPath: string[], value: string | boolean | number): void {
    if (configPropPath.length > 0) {
      set(this.FEATURE_CONFIG, [feature, ...configPropPath], value);
    }
  }

  updateLanguages(): void {
    this.LANGUAGE_LIST = this.LANGUAGE_LIST.map(lang => {
      if (lang.value === this.BASE_LANG) {
        return {
          ...lang,
          disabled: false,
        };
      }

      return {
        ...lang,
        disabled: !this.LANGUAGES.includes(lang.value),
      };
    });
  }
}
